import { template, templateSettings } from 'lodash';
import { Showroom_Apps_Inputs } from '../../types/graphql';

/**
 * Replaces handlebar style templates inside of a template string
 *
 * Use this to process templated app action values
 */
export const parseTemplateString = (value: string, inputs: Array<Pick<Showroom_Apps_Inputs, 'name' | 'value'>>) => {
  // This tells lodash to use the moustache style interpolation
  // See: https://lodash.com/docs/4.17.15#template
  templateSettings.interpolate = /{{([\s\S]+?)}}/g;

  const compiled = template(value);

  const vars = inputs.reduce((acc, input) => {
    return {
      ...acc,
      [input.name]: input.value,
    };
  }, {});

  return compiled({ inputs: vars });
};

import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useShowroom } from '../../services/showrooms/useShowroom';
import Button from '../Button';
import Card from '../Card';
import Modal from '../Modal';
import vyroSquareLogo from './vyroSquareLogo.svg';

export default function WelcomeModal() {
  const showroom = useShowroom();
  const router = useRouter();

  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(false);

  useEffect(() => {
    if (showroom.code === 'vyro' && router.query.showWelcome) {
      setIsWelcomeModalOpen(true);
    }
  }, [router.isReady, showroom.code]);

  return (
    <Modal isOpen={isWelcomeModalOpen} onClose={() => setIsWelcomeModalOpen(false)}>
      <Card style={{ width: 380 }} className="textCenter">
        <div>
          <Image src={vyroSquareLogo} alt="Vyro" width={100} height={100} />
        </div>

        <h2>Welcome to Vyro</h2>

        <p className="lead">
          Vyro has partnered with <strong>{router.query.partnerName}</strong> to provide you with access to a range of
          quality used & new electric vehicles.
        </p>

        <Button color="primary" isBlock onClick={() => setIsWelcomeModalOpen(false)}>
          Got it, thanks
        </Button>

        {typeof router.query.returnTo === 'string' && (
          <div className="textLeft">
            <Button size="small" className="smallGap" isText href={router.query.returnTo}>
              <AiOutlineArrowLeft className="inlineIcon" />
              Back to {router.query.partnerName}
            </Button>
          </div>
        )}
      </Card>
    </Modal>
  );
}

import styles from './index.module.scss';
import classnames from 'classnames';
import React from 'react';
import Link, { LinkProps } from 'next/link';

export type Props = {
  children?: React.ReactNode;
  className?: string;
  component?: any;
  isFlat?: boolean;
  href?: LinkProps['href'];
  target?: string;
  title?: string;
  onClick?: any;
  isActive?: boolean;
  size?: 'normal' | 'large';
  color?: 'gray' | 'black';
  tabs?: React.ReactNode;
  isFooterFixedOnMobile?: boolean;
  insideModalHeight?: boolean;
} & Record<string, any>;

export default function Card(props: Props) {
  const {
    children,
    className,
    isFlat,
    component,
    href,
    onClick,
    size = 'normal',
    color,
    tabs,
    target,
    title,
    isActive = false,
    isFooterFixedOnMobile = false,
    noPadding = false,
    noVerticalPadding = false,
    insideModalHeight = false,
    ...otherProps
  } = props;

  let card;
  if (href) {
    card = (
      <Link href={href}>
        <a
          target={target}
          title={title}
          onClick={onClick}
          className={styles.card}
          // This data attribute is used by the CSS in the Modal component
          data-component="card"
        >
          {children}
        </a>
      </Link>
    );
  } else {
    card = (
      <div
        onClick={onClick}
        title={title}
        className={classnames(
          styles.card,
          noPadding && styles.noPadding,
          insideModalHeight && styles.insideModalHeight,
        )}
        // This data attribute is used by the CSS in the Modal component
        data-component="card"
      >
        {children}
      </div>
    );
  }

  return (
    <div
      {...otherProps}
      className={classnames(
        styles.cardWrapper,
        isFlat && styles.isFlat,
        isFooterFixedOnMobile && styles.isFooterFixedOnMobile,
        !!tabs && styles.hasTabs,
        size && styles[size],
        color && styles[color],
        isActive && styles.isActive,
        (!!onClick || !!href) && styles.isInteractive,
        className,
      )}
    >
      {card}
      {tabs && <div className={styles.cardTabs}>{tabs}</div>}
    </div>
  );
}

type CardImageProps = {
  image: React.ReactNode;
  isFullWidth?: boolean;
  className?: string;
  overlayContent?: string;
};

Card.Image = function CardImage(props: CardImageProps) {
  const { image, isFullWidth, className, overlayContent } = props;

  return (
    <div className={classnames(styles.cardImage, isFullWidth && styles.isFullWidth, className)}>
      {overlayContent && (
        <div className={styles.overlayBackground}>
          <h4>{overlayContent}</h4>
        </div>
      )}
      {image}
    </div>
  );
};

type CardTitleProps = {
  children: React.ReactNode;
  className?: string;
};

Card.Title = function CardTitle(props: CardTitleProps) {
  const { children, className } = props;

  return <div className={classnames(styles.cardTitle, className)}>{children}</div>;
};

Card.Content = function CardContent(props: any) {
  const { children, size, noVerticalPadding, className } = props;

  return (
    <div
      className={classnames(styles.cardContent, styles[size], noVerticalPadding && styles.noVerticalPadding, className)}
    >
      {children}
    </div>
  );
};

type CardFooterProps = {
  children: React.ReactNode;
  className?: string;
};

Card.Footer = function CardFooter(props: CardFooterProps) {
  const { children, className } = props;

  return <div className={classnames(styles.cardFooter, className)}>{children}</div>;
};

type CardTabProps = {
  children: React.ReactNode;
  className?: string;
  onClick?: any;
  color?: 'primary' | 'lightPrimary' | 'gray' | 'white' | 'black';
  variant?: 'filled' | 'filled-not-active' | 'outline';
  size?: 'normal' | 'large';
  expandOnHover?: boolean;
};

Card.Tab = function CardTab(props: CardTabProps) {
  const { children, onClick, color, variant, size = 'normal', className, expandOnHover = true } = props;

  const componentProps = {
    className: classnames(
      styles.cardTab,
      styles[color],
      styles[variant],
      styles[size],
      expandOnHover && styles.expandOnHover,
      className,
    ),
  };

  if (onClick) {
    return (
      <button {...componentProps} onClick={onClick} type="button">
        {children}
      </button>
    );
  }

  return <div {...componentProps}>{children}</div>;
};

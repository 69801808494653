import NextNProgress from "nextjs-progressbar";
import { getShowroomColors } from "../../services/showrooms/colors";
import { useShowroomSettings } from "../../services/showrooms/useShowroomSettings";

export default function PageLoadProgressBar() {
  const showroomSettings = useShowroomSettings(["colors"]);
  const colors = getShowroomColors(showroomSettings.colors);

  return (
    <NextNProgress
      color={colors?.primary}
      startPosition={0.3}
      stopDelayMs={200}
      height={2}
      showOnShallow={false}
    />
  );
}

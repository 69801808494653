import { ApolloClient, from, HttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { captureException, captureMessage } from '@sentry/nextjs';
import { login } from '@vyro-x/react-auth/lib/service';
import { getAccessToken as getAccessTokenClientSide } from '@vyro-x/react-auth/lib/service/tokens';
import { abortableFetch } from 'abortcontroller-polyfill/dist/cjs-ponyfill';
import { GetServerSidePropsContext } from 'next';
import fetch from 'node-fetch';
import { getLoginRedirectServerSide } from '../../services/auth/getLoginRedirectServerSide';
import { getAccessTokenServerSide } from '../../services/auth/tokens';
import { InvalidJwtGraphQLError } from './types';

// See: https://github.com/apollographql/apollo-client/issues/6765#issuecomment-734469527
global.fetch = abortableFetch(fetch).fetch;

const getApolloClient = (getAccessToken: () => string, onAuthError: () => any) => {
  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_HASURA_HTTP_URL,
  });

  const networkLink = process.browser
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
        },
        new WebSocketLink({
          uri: process.env.NEXT_PUBLIC_HASURA_WS_URL,
          options: {
            reconnect: true,
            timeout: 30000,
            connectionCallback: (err) => {
              if (err) {
                captureMessage('websocket error', {
                  extra: {
                    err,
                  },
                });
                captureException(err);
              }
            },
            connectionParams: () => {
              const accessToken = getAccessToken();

              if (accessToken) {
                return {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                };
              }

              return { headers: {} };
            },
          },
        }),
        httpLink,
      )
    : httpLink;

  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
    },
    attempts: {
      max: 10,
    },
  });

  const authLink = setContext((operation, { headers, assumeRole }) => {
    const accessToken = getAccessToken();

    let newHeaders = headers;

    if (accessToken && assumeRole !== 'public') {
      newHeaders = {
        ...newHeaders,
        Authorization: `Bearer ${accessToken}`,
      };
    }

    if (assumeRole) {
      newHeaders = {
        ...newHeaders,
        'x-hasura-role': assumeRole,
      };
    }

    return { headers: newHeaders };
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Operation: ${JSON.stringify(
            operation,
          )}`,
        ),
      );
    }
    if (networkError) console.log(`[Network error]`, networkError);

    const hasInvalidJWT =
      graphQLErrors &&
      (graphQLErrors as unknown as InvalidJwtGraphQLError).some((err) => err.extensions?.code === 'invalid-jwt');

    if (hasInvalidJWT) {
      onAuthError();
    }
  });

  const link = from([retryLink, authLink, errorLink, networkLink]);

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });
};

/**
 * An apollo client to be used in the browser
 */
export const getApolloClientClientSide = () =>
  getApolloClient(getAccessTokenClientSide, () => {
    const url = encodeURIComponent(location.href);
    location.href = `/auth/login?post_auth_callback_url=${url}`;
  });

/**
 * An apollo client to be used on the server
 */
export const getApolloClientServerSide = (
  req: GetServerSidePropsContext['req'],
  res: GetServerSidePropsContext['res'],
) =>
  getApolloClient(
    () => getAccessTokenServerSide(req, res),
    () => {
      res.statusCode = 302;
      const url = encodeURIComponent(req.url);
      res.setHeader('Location', `/auth/login?post_auth_callback_url=${url}`);
      res.end();
    },
  );

/**
 * @deprecated use getApolloClientClientSide or getApolloClientServerSide instead
 **/
export const apolloClient = getApolloClientClientSide();

import { useEffect, useState } from 'react';
import { GTMDataLayer, GTMDataLayerContext, GTMEventName } from './types';

export const useGtm = () => {
  const [dataLayer, setDataLayer] = useState<GTMDataLayer | null>(null);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    if (typeof (window as any).dataLayer === 'undefined') {
      (window as any).dataLayer = [];
    }

    setDataLayer((window as any).dataLayer as GTMDataLayer);
  }, []);

  // console.log(dataLayer);
  return dataLayer;
};

export const useGtmEvent = (name: GTMEventName) => {
  const dataLayer = useGtm();

  useEffect(() => {
    if (!dataLayer) {
      return;
    }

    dataLayer.push({
      event: name,
    });
  }, [dataLayer, name]);
};

export const useGtmContext = (context: Partial<GTMDataLayerContext>) => {
  const dataLayer = useGtm();

  useEffect(() => {
    if (!dataLayer) {
      return;
    }

    dataLayer.push(context);
  }, [dataLayer, context]);
};

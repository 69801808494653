import { createContext, useEffect, useState } from 'react';
import Card from '../../../components/Card';
import Modal from '../../../components/Modal';
import { usePreviousValue } from '../../usePreviousValue';
import styles from './index.module.scss';

export type Alert = {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  body?: React.ReactNode;
  footer?: React.ReactNode;
  onClose?: Function;
  onConfirm?: () => any;
  confirmLabel?: React.ReactNode;
};

export const AlertsContext = createContext(null);

export default function AlertsProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const previousIsOpen = usePreviousValue(isOpen);
  const [alert, setAlert] = useState<Alert>(null);

  useEffect(() => {
    if (typeof alert?.onClose !== 'function') {
      return;
    }

    if (previousIsOpen === true && isOpen === false) {
      alert.onClose();
    }
  }, [isOpen]);

  return (
    <AlertsContext.Provider
      value={{
        setAlert,
        setIsOpen,
      }}
    >
      {children}

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <Card size="large" className={styles.alert}>
          {alert && (
            <>
              <h3 className="hug">{alert.title}</h3>
              {alert.subTitle && <p className="paragraphMedium smallGap">{alert.subTitle}</p>}
              {alert.body && <div className="gap">{alert.body}</div>}
              {alert.footer && <div>{alert.footer}</div>}
            </>
          )}
        </Card>
      </Modal>
    </AlertsContext.Provider>
  );
}

import { useEffect, useRef } from "react";

/**
 * Stores the previous value of useState
 *
 * Use this to compare the old & new values of useState inside of useEffect
 *
 * @example
 * const [isOpen, setIsOpen] = useState(false)
 * const previousIsOpenValue = usePreviousValue(isOpen);
 * useEffect(() => {
 *    if (previousIsOpenValue === true && isOpen === false) {
 *        onClose()
 *    }
 * }, [isOpen])
 *
 * @param value the useState value to watch
 * @returns the old value
 */
export function usePreviousValue<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

import { createContext, useEffect, useState } from 'react';
import { getCartClientSide, setCartClientSide } from './storage';
import { CartItem } from './types';

export type TCartContext = {
  items: Array<CartItem>;
  setItems: (items: Array<CartItem>) => any;
  isOpen: boolean;
  setIsOpen: (value: boolean) => any;
};

export const CartContext = createContext<TCartContext>({
  items: [],
  setItems: () => {},
  isOpen: false,
  setIsOpen: () => {},
});

export default function CartProvider({ children }) {
  const [items, _setItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const _items = getCartClientSide();

    if (_items && Array.isArray(_items)) {
      setItems(_items);
    }
  }, []);

  const setItems = (value) => {
    setCartClientSide(value);
    _setItems(value);
  };

  return (
    <CartContext.Provider
      value={{
        items,
        setItems,
        isOpen,
        setIsOpen,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

import classnames from 'classnames';
import React from 'react';
import { GrClose } from 'react-icons/gr';
import ReactModal from 'react-modal';
import styles from './index.module.scss';

export type Props = {
  children?: React.ReactNode;
  className?: string;
  overlayBrightness?: 'light' | 'dark';
  isOpen: boolean;
  includeCloseButton?: boolean;
  onClose: () => any;
  animation?: 'fade' | 'fade-and-scale';
  mobileFullHeight?: boolean;
} & Record<string, any>;

export default function Modal(props: Props) {
  const {
    children,
    animation,
    className,
    isOpen,
    onClose,
    mobileFullHeight = false,
    includeCloseButton = true,
    overlayBrightness = 'dark',
    ...otherProps
  } = props;

  const animationClassname = animation === 'fade' ? 'fade' : 'fadeAndScale';

  return (
    <ReactModal
      className={classnames(styles.content, mobileFullHeight && styles.mobileFullHeight, className)}
      closeTimeoutMS={800}
      overlayClassName={{
        base: classnames(styles.overlay, styles[overlayBrightness], animationClassname),
        afterOpen: styles.overlayAfterOpen,
        beforeClose: styles.overlayBeforeClose,
      }}
      // See frontends/website/styles/modal.scss for `.isModalOpen`
      bodyOpenClassName="isModalOpen"
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      {...otherProps}
    >
      <div className={styles.innerContent}>{children}</div>
      {includeCloseButton && (
        <button className={styles.close} type="button" aria-label="Close modal" onClick={() => onClose()}>
          <GrClose />
        </button>
      )}
    </ReactModal>
  );
}

Modal.Title = function Title({ children }: { children: React.ReactNode }) {
  return <h3 className={styles.title}>{children}</h3>;
};

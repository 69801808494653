import { captureException } from '@sentry/nextjs';
import axios from 'axios';
import { createContext, useEffect, useState } from 'react';
import { GeoLocation } from './getGeoLocationServerSide';

export type TGeoLocationContext = {
  isLoading: boolean;
  location: GeoLocation | null;
};

export const GeoLocationContext = createContext<TGeoLocationContext>({
  isLoading: true,
  location: null,
});

export default function GeoLocationProvider({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [location, setLocation] = useState<GeoLocation>(null);

  const locate = async () => {
    setIsLoading(true);

    try {
      const response = await axios.request({
        method: 'GET',
        url: '/api/geo-locate',
      });

      setLocation(response.data);
    } catch (err) {
      captureException(err);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    locate();
  }, []);

  return (
    <GeoLocationContext.Provider value={{ location, isLoading } as TGeoLocationContext}>
      {children}
    </GeoLocationContext.Provider>
  );
}

export const colours = {
  white: '#fff',
  whiteInverse: '#333333',

  baseForeground: '#333333',
  baseBackground: '#fff',

  black: '#333333',
  blackInverse: '#fff',

  gray: '#EBEBEB',
  grayInverse: '#333333',

  lightGray: '#f6f6f6',
  lightGrayInverse: '#333333',

  darkGray: '#5e666f',
  darkGrayInverse: '#fff',

  primary: '#00A99D',
  primaryInverse: '#fff',

  darkPrimary: '#00867c',
  darkPrimaryInverse: '#fff',

  lightPrimary: '#AFDED4',
  lightPrimaryInverse: '#00A99D',

  error: '#f96058',
  success: '#18a971',

  // Deprecated
  darkBlack: '#000',
  lightBlack: '#333333',
};

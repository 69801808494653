import { colours as coreColors } from "../../styles/variables";

const defaultColors = {
  ...coreColors,
  linkText: coreColors.primary,
  linkText__hover: coreColors.lightPrimary,
  buttonText: coreColors.white,
  buttonBackground: coreColors.black,
  buttonText__hover: coreColors.white,
  buttonBackground__hover: coreColors.darkBlack,
  buttonPrimaryText: coreColors.white,
  buttonPrimaryBackground: coreColors.primary,
  buttonPrimaryText__hover: coreColors.white,
  buttonPrimaryBackground__hover: coreColors.darkPrimary,
};

export type ShowroomColors = typeof defaultColors;

export const getShowroomColors = (
  showroomColors: Partial<ShowroomColors> = {}
): ShowroomColors => {
  return {
    ...defaultColors,
    ...showroomColors,
  };
};

export const parseColorsToCSSVars = (showroomColors: ShowroomColors) => {
  const cssVars = Object.keys(showroomColors)
    .reduce((accumulator, key) => {
      const value = showroomColors[key];
      return [...accumulator, `--${key}: ${value};`];
    }, [])
    .join(" ");

  return cssVars;
};

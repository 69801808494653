import Link, { LinkProps } from 'next/link';
import styles from './index.module.scss';
import classnames from 'classnames';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

export type Props = {
  children?: React.ReactNode;
  href?: LinkProps['href'];
  className?: string;
  isBlock?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  isText?: boolean;
  isRounded?: boolean;
  isBordered?: boolean;
  size?: 'small' | 'normal' | 'large';
  color?: 'transparent' | 'black' | 'white' | 'grey' | 'primary' | 'lightGrey' | 'darkGrey';
  type?: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>['type'];
  /** @deprecated https://nextjs.org/docs/messages/prefetch-true-deprecated  */
  prefetch?: boolean;
  isVehiclesFilter?: boolean;
} & Record<string, any>;

function Button(props: Props) {
  const {
    children,
    href,
    isBlock = false,
    className,
    isLoading = false,
    isDisabled = false,
    isText = false,
    isRounded = false,
    isBordered = false,
    size = 'normal',
    color = 'black',
    prefetch = true,
    hasBadge = false,
    type,
    ...otherProps
  } = props;

  const classlist = classnames(
    styles.button,
    styles[size],
    styles[color],
    isBlock && styles.isBlock,
    isRounded && styles.isRounded,
    isBordered && styles.isBordered,
    isLoading && styles.isLoading,
    isDisabled && styles.isDisabled,
    isText && styles.isText,
    hasBadge && styles.hasBadge,
    className,
  );

  const loadingIcon = (
    <div className={styles.loadingIcon}>
      <AiOutlineLoading3Quarters />
    </div>
  );

  if (href) {
    return (
      <Link href={href}>
        <a className={classlist} {...otherProps}>
          {children}
          {loadingIcon}
        </a>
      </Link>
    );
  }

  return (
    <button className={classlist} disabled={isDisabled || isLoading} type={type || 'button'} {...otherProps}>
      {children}
      {loadingIcon}
    </button>
  );
}

export type ButtonGroupProps = {
  children: React.ReactNode;
  justify?: 'flex-start' | 'space-between' | 'flex-end' | 'tight' | 'center';
  isStackedOnMobile?: boolean;
  isFlippedOnMobile?: boolean;
  isStackedOnTablet?: boolean;
  isFlippedOnTablet?: boolean;
};

Button.Group = function ButtonGroup(props: ButtonGroupProps) {
  const {
    children,
    justify,
    isStackedOnMobile = false,
    isFlippedOnMobile = false,
    isStackedOnTablet = false,
    isFlippedOnTablet = false,
  } = props;

  return (
    <div
      className={classnames(
        styles.buttonGroup,
        justify && styles[justify],
        isStackedOnMobile && styles.isStackedOnMobile,
        isFlippedOnMobile && styles.isFlippedOnMobile,
        isStackedOnTablet && styles.isStackedOnTablet,
        isFlippedOnTablet && styles.isFlippedOnTablet,
      )}
    >
      {children}
    </div>
  );
};

export default Button;

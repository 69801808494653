import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import PageLoadProgressBar from '../components/PageLoadProgressBar';
import WelcomeModal from '../components/WelcomeModal';
import AlertsProvider from '../services/alerts/AlertsProvider';
import { useAuth } from '@vyro-x/react-auth';
import CartProvider from '../services/cart/CartProvider';
import GeoLocationProvider from '../services/geoLocation/GeoLocationProvider';
import { useHotjar } from '../services/hotjar';
import PricingCodesProvider from '../services/pricingCodes/PricingCodesProvider';
import ShowroomProvider from '../services/showrooms/ShowroomProvider';
import { useCalculatorReferrer } from '../services/useCalculatorReferrer';
import { useUserReferrer } from '../services/useUserReferrer';
import { AuthProvider, authService } from '@vyro-x/react-auth';
import '../styles/globals.scss';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import { getApolloClientClientSide } from '../clients/hasura';
import { useAttribution } from '../services/useAttribution';
import { useGtm, useGtmContext, useGtmEvent } from '../services/useGTM';

function App({ Component, pageProps }: AppProps) {
  useCalculatorReferrer();
  useUserReferrer();

  const router = useRouter();

  const { isAuthenticated, userId, roles } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      Sentry.setUser({
        id: userId,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [isAuthenticated, userId]);

  const { collect } = useAttribution();

  useEffect(() => {
    collect();
  }, []);

  const { locale } = router;

  const lang = locale?.split('-')[0];

  dayjs.locale(lang);

  useGtmContext({
    user: {
      id: userId,
    },
  });

  return (
    <AlertsProvider>
      <ApolloProvider client={getApolloClientClientSide()}>
        <ShowroomProvider>
          <GeoLocationProvider>
            <PricingCodesProvider>
              <CartProvider>
                <PageLoadProgressBar />
                {/* @ts-ignore */}
                <Component {...pageProps} />
                <WelcomeModal />
              </CartProvider>
            </PricingCodesProvider>
          </GeoLocationProvider>
        </ShowroomProvider>
      </ApolloProvider>
    </AlertsProvider>
  );
}

function AppWithAuth(props) {
  authService.setConfig({
    authApiUrl: process.env.NEXT_PUBLIC_AUTH_API_URL,
    authFrontendUrl: process.env.NEXT_PUBLIC_AUTH_FRONTEND_URL,
  });

  return (
    <AuthProvider>
      <App {...props} />
    </AuthProvider>
  );
}

export default appWithTranslation(AppWithAuth, require('../next-i18next.config'));

import ServerSideCookies from 'cookies';
import { Cookies } from 'react-cookie';

const COOKIE_NAME = 'vyro_pricing_code';
const cookies = new Cookies();

export const getStoredPricingCodeServerSide = (req: any, res: any) => {
  return new ServerSideCookies(req, res).get(COOKIE_NAME);
};

export const getStoredPricingCodeClientSide = () => cookies.get(COOKIE_NAME);

export const setStoredPricingCodeClientSide = (value: string) =>
  cookies.set(COOKIE_NAME, value, {
    path: '/',
    maxAge: 30 * 86400,
  });

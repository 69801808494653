import { createContext, useEffect, useState } from 'react';
import { useGeoLocation } from '../geoLocation/useGeoLocation';
import { useShowroom } from '../showrooms/useShowroom';
import { getCurrentPricingCode } from './getCurrentPricingCode';
import { getGeoLocatedPricingCode } from './getGeoLocatedPricingCode';
import { getStoredPricingCodeClientSide, setStoredPricingCodeClientSide } from './storage';
import { PricingCodeOption } from './types';

export type TPricingCodesContext = {
  options: Array<PricingCodeOption>;
  current: string | null;
  setCurrent: (value: string) => any;
  pricingCode: PricingCodeOption | null;
};

export const PricingCodesContext = createContext<TPricingCodesContext>({
  options: [],
  current: null,
  setCurrent: () => {},
  pricingCode: null,
});

export default function PricingCodesProvider({ children }) {
  const showroom = useShowroom();
  const { location } = useGeoLocation();

  const options: Array<PricingCodeOption> = (showroom.showroom_pricing_codes || []).map(
    (showroomPricingCode) => showroomPricingCode.pricing_code,
  );

  const [current, setCurrentInState] = useState<string | null>(null);
  useEffect(() => {
    setCurrentInState(
      getCurrentPricingCode({
        stored: getStoredPricingCodeClientSide(),
        geoLocated: getGeoLocatedPricingCode(location, options),
        default: showroom.default_pricing_code,
        options,
      }),
    );
  }, [options, location]);

  const setCurrent = (value: string) => {
    setStoredPricingCodeClientSide(value);
    setCurrentInState(value);
  };

  const pricingCode: PricingCodeOption | null = options.find(({ code }) => code === current) || null;

  return (
    <PricingCodesContext.Provider
      value={
        {
          options,
          current,
          setCurrent,
          pricingCode,
        } as TPricingCodesContext
      }
    >
      {children}
    </PricingCodesContext.Provider>
  );
}

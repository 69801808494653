import Head from 'next/head.js';
import { createContext, useEffect, useState } from 'react';
import { PreLoadedShowroom, PreLoadedShowroomSettings } from '../../pages/api/showroom.js/index.js';
import { parseTemplateString } from '../app/parseTemplateString';
import { getShowroomColors, parseColorsToCSSVars, ShowroomColors } from './colors';

export const ShowroomContext = createContext<{
  showroom: Partial<PreLoadedShowroom>;
  settings: Partial<PreLoadedShowroomSettings>;
  colors: ShowroomColors;
}>({
  showroom: null,
  settings: null,
  colors: null,
});

export default function ShowroomProvider({ children }) {
  const [showroomError, setShowroomError] = useState<string>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showroom, setShowroom] = useState<Partial<PreLoadedShowroom>>({});
  const [settings, setSettings] = useState<Partial<PreLoadedShowroomSettings>>({});
  const [appActions, setAppActions] = useState<any>({});

  useEffect(() => {
    const _showroom = (window as any).__VYRO_SHOWROOM__;
    const _settings = (window as any).__VYRO_SHOWROOM_SETTINGS__;
    const _appActions = (window as any).__VYRO_APP_ACTIONS__;

    if (_showroom) {
      setShowroom(_showroom);
      setSettings(_settings);
      setAppActions(_appActions);
      setIsLoading(false);
    } else {
      setShowroomError(`Showroom not found client-side for domain=${window.location.origin}`);
    }
  }, []);

  if (showroomError) {
    // Don't attempt to show the showroom if it cannot be found.
    // The page simply won't render.
    // Instead, show an error.
    return <>{showroomError}</>;
  }

  const colors = getShowroomColors(settings?.colors);

  return (
    <ShowroomContext.Provider
      value={{
        showroom,
        settings,
        colors,
      }}
    >
      <Head>
        {(appActions?.actions || [])
          .filter(({ type }) => type === 'insert_head_script')
          .map((action, i) => (
            <script
              key={i}
              async={action.config.async}
              defer={action.config.defer}
              src={action.config.src && parseTemplateString(action.config.src, appActions.inputs)}
              data-vyro-apps-head-script
              dangerouslySetInnerHTML={{
                __html: parseTemplateString(action.config.inner, appActions.inputs),
              }}
            />
          ))}

        {/* Google Tag Manager */}
        {settings.google_tag_manager_id && (
          <script
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${settings.google_tag_manager_id}');`,
            }}
          ></script>
        )}

        {/* Google Analytics / Adwords */}
        {settings.google_analytics_id && (
          <>
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${settings.google_analytics_id}`}></script>
            <script
              dangerouslySetInnerHTML={{
                __html: `window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                
                  gtag('config', '${settings.google_analytics_id}');
                  ${settings.google_adwords_id ? `gtag('config', '${settings.google_adwords_id}');` : ''}`,
              }}
            ></script>
          </>
        )}

        {/* Facebook Tracking Pixel */}
        {settings.facebook_tracking_pixel_id && (
          <>
            <script
              dangerouslySetInnerHTML={{
                __html: `!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '${settings.facebook_tracking_pixel_id}');
fbq('track', 'PageView');`,
              }}
            ></script>
            <noscript>
              <img
                height="1"
                width="1"
                style={{ display: 'none' }}
                src={`https://www.facebook.com/tr?id=${settings.facebook_tracking_pixel_id}&ev=PageView&noscript=1`}
              />
            </noscript>
          </>
        )}

        {/* Favicon */}
        {showroom.code && (
          <>
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href={`/images/showrooms/${showroom.code}/favicon/apple-touch-icon.png`}
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href={`/images/showrooms/${showroom.code}/favicon/favicon-32x32.png`}
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href={`/images/showrooms/${showroom.code}/favicon/favicon-16x16.png`}
            />
            <link rel="manifest" href={`/images/showrooms/${showroom.code}/favicon/site.webmanifest`} />
            <link
              rel="mask-icon"
              href={`/images/showrooms/${showroom.code}/favicon/safari-pinned-tab.svg`}
              color="#5bbad5"
            />
          </>
        )}
      </Head>

      <div id="showroom" style={{ display: isLoading ? 'none' : 'block' }}>
        {children}
      </div>

      <style
        dangerouslySetInnerHTML={{
          __html: `
            ${
              settings?.google_font_name
                ? `@import url('https://fonts.googleapis.com/css?family=${encodeURIComponent(
                    settings?.google_font_name,
                  )}:wgth@400;500;600;700&display=swap');`
                : ''
            }

            ${
              settings?.custom_font_url
                ? Array.isArray(settings.custom_font_url)
                  ? (settings.custom_font_url as { weight: string; url: string }[])
                      .map(
                        (fontFace) =>
                          `
                          @font-face {
                            font-family: "${settings.custom_font_name}";
                            src: url("${fontFace.url}") format("woff2");
                            font-weight: ${fontFace.weight};
                            font-display: swap;
                          }`,
                      )
                      .join('')
                  : `
                  @font-face {
                    font-family: "${settings.custom_font_name}";
                    src: url("${settings.custom_font_url}") format("woff2");
                    font-display: swap;
                  }`
                : ''
            }

            :root {
              --button-border-radius: ${settings?.button_border_radius};
              --input-border-radius: ${settings?.input_border_radius};
              --font-name: ${settings?.google_font_name || settings?.custom_font_name};
              --link-weight: ${settings?.link_weight};
              --button-weight: ${settings?.button_weight};
              --link-text-decoration: ${settings?.link_text_decoration};
              --link-text-decoration__hover: ${settings?.link_text_decoration_hover};
              --letter-spacing: ${settings?.letter_spacing || 'normal'};
              ${parseColorsToCSSVars(colors)}
            }
          `,
        }}
      ></style>
    </ShowroomContext.Provider>
  );
}

import { Cookies } from 'react-cookie';
import { CartItem } from './types';

const COOKIE_NAME = 'vyro_cart';
const cookies = new Cookies();

export const getCartClientSide = () => cookies.get(COOKIE_NAME);

export const setCartClientSide = (value: Array<CartItem>) => {
  cookies.set(COOKIE_NAME, value, {
    path: '/',
    maxAge: 7 * 86400,
  });
};

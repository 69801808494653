import { PricingCodeOption } from './types';

export const getCurrentPricingCode = (args: {
  stored: string;
  geoLocated: string;
  default: string;
  options: Array<PricingCodeOption>;
}) => {
  const isValid = (code: string) => args.options.some((option) => option.code === code);

  if (isValid(args.stored)) {
    return args.stored;
  }

  if (isValid(args.geoLocated)) {
    return args.geoLocated;
  }

  return args.default;
};

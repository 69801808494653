/** @type {import('next-i18next').UserConfig} */

const path = require('path');

module.exports = {
  i18n: {
    defaultLocale: 'en-UK',
    locales: ['en-UK', 'fr-FR'],
  },
  localePath: path.resolve('./public/locales'),
  defaultNS: 'common',
  reloadOnPrerender: true,
};

import { useRouter } from "next/router";
import { useEffect } from "react";

const REFERRER_QUERY_KEY = "r";
const REFERRER_LOCAL_STORAGE_KEY = "vyro-user-referrer";

export const useUserReferrer = () => {
  const router = useRouter();

  useEffect(() => {
    if (typeof router.query[REFERRER_QUERY_KEY] === "string") {
      try {
        window.localStorage.setItem(
          REFERRER_LOCAL_STORAGE_KEY,
          router.query[REFERRER_QUERY_KEY] as string
        );
      } catch (err) {
        // noop
      }
    }
  }, [router.query]);

  const referrer = (() => {
    let value: string = null;
    try {
      value = window.localStorage.getItem(REFERRER_LOCAL_STORAGE_KEY);
    } catch (err) {
      // noop
    }
    return value;
  })();

  const parseReferrer = (val) => {
    const parsed = Number(val);

    if (isNaN(parsed)) {
      return null;
    }

    return parsed;
  };

  return parseReferrer(referrer);
};

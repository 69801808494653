import { GeoLocation } from '../geoLocation/getGeoLocationServerSide';
import { PricingCodeOption } from './types';

/**
 * Matches the client's location to a pricing code
 */
export const getGeoLocatedPricingCode = (location: GeoLocation, options: Array<PricingCodeOption>) => {
  if (!location) {
    return null;
  }

  return options.find((option) => {
    // Always require a country match
    if (option.country !== location.country) {
      return false;
    }

    // Conditionally require a region match
    // (if the pricing code option specifies a region)
    if (option.region && option.region !== location.region) {
      return false;
    }

    return true;
  })?.code;
};

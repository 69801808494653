import { useEffect, useState } from "react";

/**
 * Returns captured utm and owner_id, and also controls to set, reset it
 *
 * @returns collect, clear, ownerId, utm
 */
export const useAttribution = () => {
  const [ownerId, setOwnerId] = useState<string>("");
  const [utm, setUtm] = useState({});

  const collect = () => {
    if (window?.location?.search) {
      const params = new URLSearchParams(window?.location?.search);

      if (params) {
        if (params.has("owner_id")) {
          localStorage.setItem("owner_id", params.get("owner_id"));
        }

        const utm = {};
        params.forEach((value, key) => {
          if (key.substring(0, 4) === "utm_" && key.length > 4) {
            utm[key.substring(4)] = value;
          }
        });
        if (Object.keys(utm).length > 0) {
          localStorage.setItem("utm", JSON.stringify(utm));
        }
      }
    }
  };

  const clear = () => {
    localStorage.removeItem("owner_id");
    localStorage.removeItem("utm");

    setUtm({});
    setOwnerId("");
  };

  useEffect(() => {
    try {
      if (window && window.localStorage) {
        setOwnerId(window.localStorage.getItem("owner_id"));
        setUtm(JSON.parse(window.localStorage.getItem("utm")));
      }
    } catch (err) {
      // do nothing
    }
  }, []);

  return { utm, ownerId, collect, clear };
};

import { gql, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { ShowroomContext } from './ShowroomProvider';

export const useShowroomSettings = <Key extends string>(keys: Array<Key>) => {
  const { showroom, settings: preloadedSettings } = useContext(ShowroomContext);

  // Only load the settings which haven't been pre-loaded
  const filteredKeys = keys.filter((key) => !preloadedSettings.hasOwnProperty(key));

  const settingsGQL = filteredKeys
    .map((key) => {
      return `${key}: public_settings(path: "${key}")`;
    })
    .join('\n');

  const response = useQuery(
    gql`
    query GetShowroomSettingsClientSide ($domain: String)  {
      showrooms(where: { domain: { _eq: $domain } }) {
        id
        ${settingsGQL}
      }
    }
  `,
    {
      variables: {
        domain: showroom.domain,
      },
      skip: !showroom.domain || !filteredKeys.length,
    },
  );

  const data = response.data?.showrooms[0];

  return keys.reduce((acc, key) => {
    return {
      ...acc,
      [key]: data?.[key] ?? preloadedSettings[key as keyof typeof preloadedSettings],
    };
  }, {} as Record<Key, any>);
};
